import { Space } from 'antd'
import { Text } from 'src/components/nativeCompat/Text'
import { ReportLabel } from '../../report-label-inspection'
import { ReportInspectionAreaSection } from './ReportInspectionAreaSection'
import { ReportLabelImage } from './ReportLabelImage'

type Props = {
  reportLabel: ReportLabel
}

export const ReportLabelSection = ({ reportLabel }: Props) => {
  return (
    <Space direction="vertical" size="middle" key={reportLabel.labelId}>
      <Text>{reportLabel.name}</Text>

      <ReportLabelImage
        url={reportLabel.resultImageUrl}
        recordedAt={reportLabel.resultImageRecordedAt}
      />

      <Space direction="vertical" size="small">
        <Text>解析結果</Text>
        <Space direction="vertical" size="small">
          {reportLabel.reportLabelInspectionAreas.map(area => (
            <ReportInspectionAreaSection
              key={area.labelInspectionAreaId}
              reportInspectionArea={area}
            />
          ))}
        </Space>
      </Space>
    </Space>
  )
}
