import { PhotoSlider } from 'src/features/reports/result/components/reportResult/PhotoSlider/PhotoSlider'

type Props = {
  url: string
  recordedAt: string
}

export function ReportLabelImage({ url, recordedAt }: Props) {
  return (
    <PhotoSlider
      photos={[
        {
          url,
          recordedAt: new Date(recordedAt),
        },
      ]}
      onImageTouched={() => {}}
      isTimeDisplayed={true}
    />
  )
}
