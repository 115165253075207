import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { Image } from '@ulysses-inc/harami_api_client'
import React from 'react'
import notFoundImage from 'src/assets/images/imageNotFound1000x1000.png'
import Grid from 'src/features/reports/result/downloadPDF/Grid'
import ReactPDFImage from 'src/features/reports/result/downloadPDF/ReactPDFImage'

const styles = StyleSheet.create({
  titleLabel: {
    padding: 5,
    fontSize: 8,
    color: '#656565',
  },
  images: {
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginBottom: 16,
  },
  imageColumn: {
    margin: 10,
    flexDirection: 'column',
  },
  image: {
    height: 'auto',
    width: 'auto',
    maxHeight: 200,
    maxWidth: 200,
    objectFit: 'contain',
    marginTop: 10,
    marginRight: 10,
  },
  optionImages: {
    width: '100%',
  },
})

interface OwnProps {
  images?: Array<Image>
}

const InformationImages: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <Grid borderLeft borderRight style={styles.optionImages}>
      <View>
        <Text style={styles.titleLabel}>- 説明画像</Text>
        <View style={styles.images}>
          {(props.images ?? []).map((image: Image) => (
            <View key={image.id} style={styles.imageColumn}>
              <ReactPDFImage
                style={styles.image}
                uri={
                  image?.url !== undefined
                    ? `${image?.url ?? ''}`
                    : notFoundImage
                }
              />
            </View>
          ))}
        </View>
      </View>
    </Grid>
  )
}

export default InformationImages
