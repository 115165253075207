import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { Image } from '@ulysses-inc/harami_api_client'
import React from 'react'
import notFoundImage from 'src/assets/images/imageNotFound1000x1000.png'
import BreakText from 'src/features/reports/result/downloadPDF/BreakText'
import Grid from 'src/features/reports/result/downloadPDF/Grid'
import ReactPDFImage from 'src/features/reports/result/downloadPDF/ReactPDFImage'
import RecordedTime from './RecordedTime'

const styles = StyleSheet.create({
  images: {
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginBottom: 30,
    marginTop: 10,
    width: '100%',
  },
  imageColumn: {
    flexDirection: 'column',
  },
  image: {
    height: 'auto',
    width: 'auto',
    maxHeight: 200,
    maxWidth: 200,
    objectFit: 'contain',
    marginTop: 15,
    marginRight: 15,
  },
  gridImage: { width: '100%' },
  fullWidthWrap: { width: '100%' },
  notAnswerText: { padding: 5 },
})

interface OwnProps {
  questionName: string
  images?: Array<Image>
  recordedAt?: Date
  isTimeDisplayed: boolean
}

const Images: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <Grid borderLeft borderRight style={styles.fullWidthWrap}>
      <View style={styles.images}>
        <View style={styles.fullWidthWrap}>
          <BreakText text={props.questionName} />
        </View>
        {(props.images === undefined ||
          (props.images !== undefined && props.images?.length === 0)) && (
          <Text style={styles.notAnswerText}>未回答</Text>
        )}
        {props.images !== undefined &&
          props.images.map((image: Image) => (
            <View key={image.uuid} style={styles.imageColumn}>
              <View>
                <ReactPDFImage
                  style={styles.image}
                  uri={image.url !== undefined ? `${image.url}` : notFoundImage}
                />
                <RecordedTime
                  isTimeDisplayed={props.isTimeDisplayed}
                  recordedAt={props.recordedAt}
                />
              </View>
            </View>
          ))}
      </View>
    </Grid>
  )
}

export default Images
